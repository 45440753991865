<template>
  <div v-if="getLoggedStatus" class="agent-logged">
    <div class="console-header">
      <div class="console-buttons">
        <el-button
          @click.native="handleLogout"
          class="btn-close-session"
          :disabled="getIsInCall"
          >{{ $t("agent-console.buttons.close-session") }}</el-button
        >
        <el-button @click="$store.dispatch('toggleAvailableForChats')" v-if="agent.chat" :type="availableForChats ? 'normal' : 'danger'" >
          {{ availableForChats ? $t('agent-console.buttons.change-status-not-available') : $t('agent-console.buttons.change-status-available') }}
        </el-button>
        <el-button v-if="getBreakStatus.id === 0 && agent.call" :disabled="!getLoggedInCallCenter()" @click.native="showModalBreaks">{{ $t("agent-console.buttons.got-to-break") }}</el-button>
        <el-button v-if="getBreakStatus.id > 0 && agent.call" type="danger" @click.native="unBreak">{{ $t("agent-console.buttons.out-of-break") }}</el-button>
        <el-button v-if="agent.call" @click.native="showNewCallDrawer" :disabled="!isCallButtonEnabled">{{ $t("agent-console.buttons.new-call") }}</el-button>
      </div>
      <CallButtons
        v-if="getIsInCall"
        @showTransferDrawer="showTransferDrawer"
        :janus="janus"
        :stats="stats"
      ></CallButtons>
      <div v-else>
        <el-tooltip effect="dark" placement="bottom" v-for="(button, index) in quickDialButtons" :key="index" :content="button.label">
          <el-button circle @click="handleQuickButton(button)">
            <i class="fas fa-phone"></i>
          </el-button>
        </el-tooltip>
      </div>
    </div>
    <div style="flex: 1; display: flex">
      <div class="console" :class="{ 'show-content': getActiveItem !== null && !showAcceptChat }">
        <div class="agent-activity">
          <div class="agent-info box">
            <div class="row" style="margin-left: 0">
              <p>{{ $t("agent-console.agent") }} {{ agent.number }}</p>
            </div>
            <ul>
              <li :class="{ active: getLoggedInCallCenter() }" v-if="agent.call">
                {{ $t("agent-console.call") }} ({{ textCallLogged }})
              </li>
              <li :class="{ active: getLoggedInChatCenter() }" v-if="agent.chat">
                {{ $t("agent-console.chat.chat") }} ({{ textChatLogged }}) <span v-if="!availableForChats" class="not-available">{{ $t('agent-console.not-available') }}</span>
              </li>
            </ul>
            <chronometer type="clock" style="display: block"></chronometer>
          </div>
          <AgentInBreak v-if="getBreakStatus.id !== 0" :name="getBreakStatus.name" :startedAt="getBreakStatus.started_at"
            type="bar" class="agent-in-break-mobile"></AgentInBreak>
          <TheActivityBox :class="{ 'hide': getActiveItem !== null && !showAcceptChat }" style="max-width:350px" />
        </div>
        <div class="main-agent-content" :class="{ 'show': getActiveItem !== null && !showAcceptChat }">
          <el-alert v-if="agent.call && !extensionRegistered" type="warning" :closable="false">
            {{
              $t(
                "agent-console.your-extension-is-not-registered-you-will-not-receive-calls"
              )
            }}
          </el-alert>
          <AgentInBreak v-if="getBreakStatus.id !== 0" :name="getBreakStatus.name" :startedAt="getBreakStatus.started_at"
            :type="[getActiveItem || showAcceptChat ? 'bar' : 'ilustration']" class="true-break"></AgentInBreak>
          <el-alert v-if="getActiveItem &&
            getActiveItem.type === 'chat' &&
            !isAgentLogged
            " :title="$t(
    'agent-console.the-chat-is-in-read-only-mode-as-it-was-not-possible-to-log-in-correctly-as-an-agent'
  )
    " type="warning" :closable="false"></el-alert>
          <TheModalTour :title="$t('main-menu.agent-console.title')" tour="agent-console-basic" />

          <TheMainAgentContent v-if="getActiveItem && Object.keys(getActiveItem).length > 0 && !showAcceptChat"
            :callKey="getActiveItem.callKey" :title="getActiveItem.title" :type="getActiveItem.type"
            :data="getActiveItem.data" :date="getActiveItem.date" :active="getActiveItem.active"
            :forms="getActiveItem.forms" :idCall="getActiveItem.id_call" :active-client="getActiveItem"
            @showTransferDrawer="showTransferDrawer" />
          <div v-if="!getActiveItem && getBreakStatus.id === 0 && !showAcceptChat
            " style="align-self: center; margin-top: 30%">
            <div v-if="!errorLoggin">
              <img src="@/img/undraw_Working_oh83.svg" style="width: 20vw; margin: 0 auto; display: block" />
              <p style="text-align: center; margin-top: 10px">
                {{ $t("agent-console.no-activity-selecected") }}
              </p>
            </div>
            <div v-else>
              <p style="text-align: center; margin-top: 10px">
                {{
                  $t(
                    "agent-console.errors.a-problem-has-occurred-and-you-are-not-logged"
                  )
                }}
              </p>
              <el-button @click="retry" style="margin: 0 auto; display: block">{{ $t("agent-console.retry") }}</el-button>
            </div>
          </div>
          <TheAceptChatWindow v-if="showAcceptChat" @chatAccepted="chatAccepted" @closeWindow="closeWindow">
          </TheAceptChatWindow>
        </div>
        <div
          :class="['agent-console__side-bar-container']"
          style="max-width:350px; display: flex; flex-direction: column; overflow-y: auto; max-height: 100vh; height: 100%; background-color: white"
          v-if="getActiveItem && !showAcceptChat"
        >
        <div v-if="showChatDetails" style="height: 100%; max-width: 350px;">
            <TheClientBox v-if="getActiveItem.type === 'chat' && customer" :client="customer" />
            <TheIntegrationsBox
              v-if="getActiveItem.integrations"
              :integrations="getActiveItem.integrations"
            ></TheIntegrationsBox>
            <TheActivityInfoBox
              @sendEmail="showBoxSendEmail"
              @searchTicket="showBoxSearchTicket"
              @call="call"
              v-if="
                getActiveItem.clientinfo &&
                getActiveItem.clientinfo.length > 0
              "
              :client-info="getActiveItem.clientinfo"
              :activity-key="getActiveItem.key"
              :crud-enabled="clientInfoCrudEnabled"
            ></TheActivityInfoBox>
            <TheTagsBox :tags="getActiveItem.data.tags" v-if="getActiveItem.type === 'chat' && getActiveItem.data.tags.length > 0"></TheTagsBox>
            <TheSystemMessages :room-id="getActiveItem.data.roomId" v-if="getActiveItem.type === 'chat'"></TheSystemMessages>
            <TheInternalNotesBox v-if="getActiveItem.type === 'chat'" :room-id="getActiveItem.data.roomId"></TheInternalNotesBox>
            <TheSendEmailBox
              @closeEmailBox="closeEmailBox"
              :destination="sendEmailTo"
              v-if="sendEmailTo"
            ></TheSendEmailBox>
            <TheSearchTicket
              :email="searchTicketOf"
              @cancelSearchTicket="searchTicketOf = ''"
              v-if="searchTicketOf"
              ref="theSearchTicket"
            ></TheSearchTicket>
        </div>
        <div v-if="getActiveItem.type === 'chat'" :class="['agent-console__side-bar-controls-container', {'agent-console__side-bar-controls-container--open': showChatDetails}]">
          <i v-if="showChatDetails" @click="changeShowChatDetails(false)" class="agent-console__side-bar-controls fas fa-chevron-right"></i>  
          <i v-else @click="changeShowChatDetails(true)" class="agent-console__side-bar-controls fas fa-chevron-left"></i>
        </div>
      </div>
      </div>
    </div>
    <the-search-client ref="theSearchClient"></the-search-client>
    <NewCallDrawer ref="newCallDrawer" :janus="janus" />
    <TheNewUserDrawer ref="newUserDrawer"></TheNewUserDrawer>
    <TransferDrawer ref="transferDrawer" :janus="janus" :type-transfer="typeTransfer" />
    <TheModalBreaks ref="modalBreak" />
    <TheConfirmationLogoutModal ref="TheConfirmationLogoutModal" />
    <video ref="sipAudio" autoplay playsinline style="display: none" loop></video>
    <TheAgentMenu></TheAgentMenu>
    <IncomingCall :callerid="inCallWith.displayname" :janus="janus" :jsep="jsep" ref="incomingCall" />
    <audio ref loop>
      <source src="/sounds/Slow_Asus_Zenfone_5Z_Stock-635292.mp3" type="audio/ogg" />
    </audio>
    <audio ref="hangupSound">
      <source src="/sounds/hangup.mp3" type="audio/ogg" />
    </audio>
  </div>
  <div v-else class="agent-not-logged">
    <div>
      <img src="@/img/undraw_contact_us_15o2.svg" class="image-not-logged" />
      <div v-if="appReady">
        <div v-if="canLoggin">
          <p>
            <span v-if="!loginClick">{{ $t("agent-console.not-logged-in-as-an-agent-yet") }}</span>
            <span v-else>{{ $t("agent-console.you-have-logged-out-as-an-agent") }}</span>. {{
              $t("agent-console.to-login-to-start-receiving") }} {{ agentResponds }}.
          </p>
          <el-button @click="doAgentLogin">{{ $t("agent-console.login") }}</el-button>
        </div>
        <div v-else>
          <p v-if="!errorCanLoggin"> {{
            $t("agent-console.you-cannot-log-in-as-an-agent-because-the-maximum-number-of-agents-has-already-been-reached") }}
          </p>
          <p v-else>{{ $t("agent-console.could-not-get-if-sessions-are-available-try-again") }}</p>
          <el-button @click.native="doAgentLogin">{{ $t("agent-console.retry") }}</el-button>
        </div>
      </div>
      <div v-else>
        <p>{{ $t("agent-console.please-wait") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import agentapi from "@/api/agent";
import mediaServerApi from "@/api/mediaserver";
import { mapGetters, mapActions } from "vuex";
import janus from "@/plugins/janus-proxy";
import CallButtons from "@/components/agent-console/CallButtons";
import TheSystemMessages from "@/components/agent-console/TheSystemMessages";
import TheSendEmailBox from "@/components/agent-console/TheSendEmailBox";
import TheSearchTicket from "@/components/agent-console/TheSearchTicket";
import NewCallDrawer from "@/components/webrtc/NewCallDrawer";
import TransferDrawer from "@/components/webrtc/TransferDrawer";
import AgentInBreak from "@/components/agent-console/AgentInBreak";
import TheActivityBox from "@/components/agent-console/TheActivityBox";
import TheActivityInfoBox from "@/components/agent-console/TheActivityInfoBox";
import TheMainAgentContent from "@/components/agent-console/TheMainAgentContent";
import TheModalBreaks from "@/components/agent-console/TheModalBreaks";
import TheAgentMenu from "@/components/agent-console/TheAgentMenu";
import IncomingCall from "@/components/webrtc/IncomingCall";
import TheIntegrationsBox from "@/components/agent-console/TheIntegrationsBox";
import TheAceptChatWindow from "@/components/agent-console/TheAceptChatWindow";
import TheConfirmationLogoutModal from "@/components/agent-console/TheConfirmationLogoutModal";
import TheTagsBox from "@/components/agent-console/TheTagsBox";
import TheModalTour from "@/components/modal-tour/TheModalTour";
import createNotification from "@/plugins/notification";
import Chronometer from "@/components/common/Chronometer";
import TheNewUserDrawer from "@/components/ticket/TheNewUserDrawer";
import TheSearchClient from "@/components/ticket/TheSearchClient";
import TheClientBox from '@/components/agent-console/TheClientBox';
import TheInternalNotesBox from '@/components/agent-console/TheInternalNotesBox';
import { EventBus } from '@/plugins/event-bus.js';

export default {
  name: "AgentConsole",
  title: "Agent Console",
  components: {
    TheClientBox,
    TheTagsBox,
    TheActivityBox,
    TheMainAgentContent,
    TheActivityInfoBox,
    TransferDrawer,
    AgentInBreak,
    TheModalBreaks,
    TheSendEmailBox,
    CallButtons,
    NewCallDrawer,
    TheAgentMenu,
    IncomingCall,
    TheIntegrationsBox,
    TheAceptChatWindow,
    TheSearchTicket,
    TheConfirmationLogoutModal,
    TheModalTour,
    Chronometer,
    TheNewUserDrawer,
    TheSearchClient,
    TheSystemMessages,
    TheInternalNotesBox
  },
  data () {
    return {
      agent: this.getAgent(),
      sendEmailTo: "",
      transferDrawerIsVisible: false,
      extensionRegistered: false,
      appReady: false,
      inCallWith: {
        username: "",
        displayname: "",
      },
      incomingCall: false,
      typeTransfer: "call",
      loginClick: false,
      searchTicketOf: "",
      canLoggin: false,
      errorLoggin: false,
      jsep: null,
      answerTimeout: null,
      errorCanLoggin: false,
      janusConnected: false,
      stats: null,
      customer: null,
      loadingCustomer: false,
      showChatDetails: false
    };
  },
  methods: {
    ...mapGetters([
      "getBreak",
      "getLogged",
      "getAgent",
      "getLoggedInCallCenter",
      "getLoggedInChatCenter",
      "getActiveTabId",
      "getShowAcceptChatWindow",
      "getActivityList",
    ]),
    ...mapActions([
      "setAgentJanus",
      "doLogout",
      "doLogin",
      "unBreak",
      "doBreak",
      "setAgentBasic",
      "listenAgent",
      "setInCall",
      "checkIfLogged",
      "fetchBreaks",
      "updateStatus",
      "listenAgentsDashboard",
      "listenQueuesDashboardArray",
      "listenToInactiveChats",
      "doCallCenterLogin",
      "setPendingChatAcept",
      "removeClick2Call",
      "closeLastCall",
      "doOsticketAuth",
      "fetchTickets"
    ]),
    ...mapActions('audit', [
      'saveAgentErrorLogin'
    ]),
    handleLogout () {
      if (this.activeChats.length > 0) {
        this.$refs.TheConfirmationLogoutModal.show = true;
        return;
      }

      this.doLogout();

      if (this.agent.extension) {
        if (this.janus) {
          this.janus.destroy();
        }
        this.setAgentJanus(null);
      }
    },
    showTransferDrawer (data) {
      this.$refs.transferDrawer.drawer = true;
      this.$refs.transferDrawer.typeTransfer = data.type;
      this.$refs.transferDrawer.data = data.data;
    },
    showNewCallDrawer () {
      this.$refs.newCallDrawer.show = true;
    },
    showNewUserDrawer () {
      this.$refs.newUserDrawer.show = true;
    },
    chatAccepted () {
      this.setPendingChatAcept(null);
    },
    closeWindow () {
      this.setPendingChatAcept(null);
    },
    showModalBreaks () {
      this.$refs.modalBreak.show = true;
    },
    showBoxSendEmail (email) {
      this.sendEmailTo = email;
    },
    call (value) {
      this.$refs.newCallDrawer.form.name = value;
      this.$refs.newCallDrawer.call({ number: value, title: value }, true);
    },
    showBoxSearchTicket (email) {
      this.searchTicketOf = email;
      setTimeout(() => {
        this.$refs.theSearchTicket.searchTicket();
      }, 200);
    },
    closeEmailBox () {
      this.sendEmailTo = "";
    },
    callbackSocketRemote (text) {
      this.$store.dispatch('addTranscription', { transcription: { timestamp: new Date().getTime(), text, client: true } })
    },
    callbackSocketLocal (text) {
      this.$store.dispatch('addTranscription', { transcription: { timestamp: new Date().getTime(), text, client: false } })
    },
    async doAgentLogin () {
      this.errorLoggin = false;
      this.loginClick = true;

      await this.fetchCanLoggin();

      if (!this.canLoggin) {
        return;
      }

      if (this.agent.call) {
        try {
          await this.initJanus();
        } catch (error) {
          console.log(error);
        }
      }

      this.doLogin();

      setTimeout(() => {
        if (this.getLogged()) {
          if (this.agent.call && !this.getLoggedInCallCenter()) {
            this.errorLoggin = true
            this.saveAgentErrorLogin({
              reason: 'Timeout',
              code: '1396',
              by: 'web'
            })
          }

          if (this.agent.chat && !this.getLoggedInChatCenter()) {
            this.errorLoggin = true;
            this.$notify({
              type: "error",
              title: this.$t("agent-console.failed-to-login"),
              message: this.$t(
                "agent-console.errors.a-problem-has-occurred-and-you-are-not-logged-into-the-chat-center"
              ),
              position: "bottom-right",
              duration: 0,
            });
          }
        }
      }, 15000)
    },
    initJanus () {
      return new Promise((resolve, reject) => {
        const agent = this.getAgent();
        const extension = agent.extension;
        if (extension) {
          if (this.janus !== null) {
            this.janus.registerSipExtension();
            resolve();
            return;
          }
          const stunServer = extension.stunServer ?? null;
          const _JANUS = janus.init(
            extension.janus_server.servers,
            extension.janus_server.secret,
            {
              number: parseInt(extension.extension),
              displayname: this.agent.name,
              domain: extension.domain,
              secret: extension.secret
            },
            stunServer,
            this.agent.enableTranscription ? this.callbackSocketRemote : null,
            this.agent.enableTranscription ? this.callbackSocketLocal: null,
            this.organizationId
          );

          _JANUS.onSipMessage((message, jsep) => {
            if (message.result) {
              if (message.result.event === 'registration_failed') {
                this.saveAgentErrorLogin({
                  reason: 'Registration forbidden (Bad auth)',
                  code: '4131',
                  by: 'web'
                })
              }

              if (message.result.event === "registered") {
                _JANUS.attachAudio(document.querySelector("#agentConsoleAudio"));
                this.extensionRegistered = true;
                if (this.getLoggedInCallCenter()) {
                  this.$store.dispatch("SET_DO_UNREGISTER_REGISTER");
                }
              }

              if (message.result.event === "registration_failed") {
                this.extensionRegistered = false;
              }

              if (message.result.event === "incomingcall" && this.getLoggedInCallCenter()) {
                this.jsep = jsep;
                this.inCallWith.username = message.result.username;
                this.inCallWith.displayname = message.result.displayname.replace('"', '').replace('"', '')
                if (this.agent.automatic_answer_call) {
                  this.answerTimeout = setTimeout(() => {
                    try {
                      this.janus
                        .answer(this.jsep);
                    } catch (error) {
                      console.log(error);
                    }
                  }, 1000)
                } else {
                  this.incomingCall = true;
                  this.$refs.incomingCall.toggleVisible();
                  createNotification("Tienes una llamada entrante", this.interactWithPage);
                }
              }

              if (message.result.event === "accepted") {
                if (this.incomingCall) {
                  if (this.$refs.incomingCall) {
                    this.$refs.incomingCall.toggleVisible();
                  }
                }

                let callerid = this.inCallWith.displayname;

                if (this.$refs.newCallDrawer.show && this.$refs.newCallDrawer.callTo) {
                  callerid = this.$refs.newCallDrawer.callTo.number
                }

                if (callerid) {
                  this.$message({
                    showClose: true,
                    message: this.$t("agent-console.you-are-in-call-with", { callerid }),
                    type: "success",
                    duration: 10000 ,
                  });
                }

                this.setInCall();
                this.updateStatus("busy");
                if (this.$refs.newCallDrawer.show) {
                  this.$refs.newCallDrawer.closeAndReset();
                }
                //this.$refs.ringtone.pause();
                if (this.agent.automatic_answer_call) {
                  createNotification("Se atendio la llamada");
                }
              }

              if (message.result.event === "hangup") {
                this.hangup()
              }

              if (
                message.result.event == "progress" ||
                message.result.event == "accepted" ||
                message.result.event == "hangup"
              ) {
                clearTimeout(this.answerTimeout);
                this.answerTimeout = null;
                this.incomingCall = false;
                if (this.$refs.newCallDrawer) {
                  if (this.$refs.newCallDrawer.show) {
                    this.$refs.newCallDrawer.pauseRingback();
                  }
                }
              }

              if (message.result.event === 'holding' || message.result.event === 'resuming') {
                this.$store.dispatch('setHoldingValue', message.result.event === 'holding')
              }
            }
          });

          _JANUS.onCallingError(() => {
            if (this.$refs.newCallDrawer) {
              if (this.$refs.newCallDrawer.show) {
                this.$refs.newCallDrawer.closeAndReset();
              }
            }
          });

          _JANUS.onErrorAttachSip((error) => {
            this.$alert(error);
          });

          _JANUS.onDestroyedSession(() => {
            this.onDestroySession();
          });

          _JANUS.startConnection().then(() => {
              mediaServerApi.saveAgentSession(_JANUS.server(), _JANUS.sessionId())
              this.appReady = true;
              this.janusConnected = true;
              _JANUS.attachAudio(this.$refs.sipConsoleAudio);
              _JANUS.startPing((connected) => {
                if (!connected) {
                  _JANUS.destroy(() => {
                    this.onDestroySession();
                  });
                }
              });
              this.setAgentJanus(_JANUS);
              //agentapi.saveJanusConnection(_JANUS.server(), _JANUS.sessionId());
              resolve();
            })
            .catch((error) => {
              setTimeout(() => {
                this.onDestroySession();
              }, 10000)
              reject(error);
            });
        } else {
          return resolve();
        }
      });
    },
    onDestroySession() {
      this.janusConnected = false;
      if (this.incomingCall) {
        this.$refs.incomingCall.toggleVisible();
        this.incomingCall = false;
      }
      if (this.janus) {
        this.janus.stopAllTracks();
      }
      this.setAgentJanus(null);
      setTimeout(() => {
       if (this.getLogged()) {
          this.initJanus();
        }   
      }, 5000)
    },
    fetchCanLoggin () {
      this.appReady = false;
      this.errorCanLoggin = false
      return agentapi.fetchCanLoggin()
        .then((response) => {
          this.canLoggin = response.data;
          this.appReady = true;

          if (!this.canLoggin) {
            this.saveAgentErrorLogin({
              reason: 'Cannot login as an agent because the maximum number of agents has already been reached',
              code: '1464',
              by: 'web'
            })
          }
        }).catch(() => {
          this.appReady = true;
          this.errorCanLoggin = true
          this.saveAgentErrorLogin({
            reason: 'Cannot login as an agent because there was an error trying to get the sessions',
            code: '1464',
            by: 'web'
          })
        })
    },
    retry () {
      this.handleLogout();

      if (this.agent.call) {
        this.setAgentJanus(null);
      }

      this.doAgentLogin();
    },
    hangup () {
      if (this.incomingCall) {
        this.incomingCall = false;
        if (this.$refs.incomingCall) {
          this.$refs.incomingCall.toggleVisible();
        }
      }

      if (this.getIsInCall) {
        this.$refs.hangupSound.volume = 0.5;
        this.$refs.hangupSound.play();
        this.$refs.hangupSound.currentTime = 0;

        if (this.$store.getters['getLastCallUniqueId'] && this.janus) {
          this.janus.setUniqueId(this.$store.getters['getLastCallUniqueId']) 
        }
      }

      this.setInCall(false);
      this.inCallWith.username = "";
      this.inCallWith.displayname = "";
      this.updateStatus("connected");

      if (this.$refs.newCallDrawer) {
        if (this.$refs.newCallDrawer.show) {
          this.$refs.newCallDrawer.closeAndReset();
        }
      }

      if (this.$refs.transferDrawer) {
        this.$refs.transferDrawer.close();
      }

      if (this.$refs.ringtone) {
        this.$refs.ringtone.pause();
      }

      if (this.click2call) {
        this.removeClick2Call();
      }

      this.closeLastCall();
      this.$store.commit("SET_CALLING_FROM_MAILCENTER", false);
      this.$store.dispatch('setHoldingValue', false)
    },
    handleQuickButton(button) {
      if (this.$refs.newCallDrawer) {
        this.$refs.newCallDrawer.externalCall(button.value);
      }
    },
    changeShowChatDetails(value){
        localStorage.setItem('showChatDetails',value)
        this.showChatDetails = value
    }
  },
  computed: {
    ...mapGetters({
      availableForChats: "getAvailableForChats",
      outputDevice: "getOutputAudioDevice",
      inputDevice: "getInputAudioDevice",
      volume: "getOutputAudioVolume",
      hasToReloadJanus: "reloadJanus",
      click2call: "getClick2Call",
      isMainWindow: "getIsMainWindow",
      janus: "getAgentJanus",
      activityList: "getActivityList",
      interactWithPage: 'getInteractWithPage',
      user: 'getUser',
      DO_UNREGISTER_REGISTER: "DO_UNREGISTER_REGISTER",
      isLoggedInCallCenter: "getLoggedInCallCenter",
      getActiveItem: 'getActiveItem',
      getIsInCall: 'getIsInCall'
    }),
    quickDialButtons() {
      return this.agent.extension ? this.agent.extension.quick_buttons.filter(b => b.type === 'quick_dial') : []
    },
    clientInfoCrudEnabled () {
      return this.getActiveItem.type === "chat" && this.getActiveItem.createdWitChatEngine && this.getActiveItem.active;
    },
    isLastActivityItemActive() {
      const items = this.activityList.filter(item => item.type === 'call');
      return items[0] ? items[0].active : false
    },
    showAcceptChat () {
      return this.getShowAcceptChatWindow();
    },
    getLoggedStatus () {
      return this.getLogged();
    },
    getBreakStatus () {
      return this.getBreak();
    },
    agentResponds () {
      if (this.agent.call && this.agent.chat) {
        return this.$t("agent-console.calls-and-chats").toLowerCase();
      }

      if (this.agent.call) {
        return this.$t("agent-console.calls").toLowerCase();
      }

      return this.$t("agent-console.chats").toLowerCase();
    },
    isCallButtonEnabled() {
      if (this.extensionRegistered && this.getLoggedInCallCenter() && !this.getIsInCall) {
        return true;
      }
      return false;
    },
    textCallLogged () {
      return this.getLoggedInCallCenter()
        ? this.$t("agent-console.online")
        : this.$t("agent-console.offline");
    },
    textChatLogged () {
      return this.getLoggedInChatCenter()
        ? this.$t("agent-console.online")
        : this.$t("agent-console.offline");
    },
    tabId () {
      return localStorage.getItem("tabCount");
    },
    isAgentLogged () {
      if (
        this.getActiveItem &&
        this.agent.id &&
        this.getLoggedInChatCenter()
      ) {
        return true;
      }
      return false;
    },
    activeChats () {
      return this.getActivityList().filter(
        (item) => item.active && item.type === "chat"
      );
    },
    organizationId () {
			return this.$store.getters["getAgent"].organization_id.toString()
		},
    getPhoneOfActivity() {
      if (this.getActiveItem) {
        if (this.getActiveItem.type === 'chat') {
          return this.getActiveItem.clientinfo.find(prop => prop.label === 'Phone')?.value
        }
      }
      return null
    },
    getUniqueIdActiveCall() {
      return this.$store.getters['getCallKeyActive']
    }
  },
  watch: {
    /* getUniqueIdActiveCall(value) {
      if (value && this.janus) {
        this.janus.setUniqueId(value);
      }
    },*/
    getIsInCall(value, old) {
      console.log({ log: 'agent-console-watch', payload: { newValue: value, oldValue: old }})
    },
    DO_UNREGISTER_REGISTER(value) {
      if (value && this.janus) {
        this.janus.unregisterSipExtension();
      }
    },
    volume (value) {
      document.querySelector("#agentConsoleAudio").volume = value;
    },
    outputDevice (value) {
      if (document.querySelector("#agentConsoleAudio").setSinkId) {
        document.querySelector("#agentConsoleAudio").setSinkId(value);
      }
    },
    inputDevice (value) {
      this.janus.changeMicrophone(value);
    },
    click2call (value) {
      if (value) {
        this.call(value.callTo);
      }
    },
    lastActivityList (value, oldValue) {
      if (!value && oldValue) {
        this.hangup();
      }
    },
    activityList: {
      handler: function (value, oldValue) {
        if (value.length !== 0) {
          const newItem = value[0];
          if (newItem.type === 'call' && newItem.active) {
            if (oldValue[0]) {
              const oldItem = oldValue[0];
              if (oldItem.key !== newItem.key) {
                this.setInCall();
              }
            } else {
              this.setInCall();
            }
          }
        }
      },
      deep: true,
    },
    getLoggedStatus (value) {
      if (!value) {
        createNotification('Se ha cerrado la sesión de agente')
        const audio = new Audio('/sounds/sign-out.mp3')
        audio.play()
        if (this.agent.call && this.janus) {
          this.onDestroySession();
        }
      }
    },
    async getActiveItem(item) {
      if (item && item.type === 'chat' && this.agent.customersEnabled && this.getPhoneOfActivity) {
        this.customer = null
        this.loadingCustomer = true
        try {
          this.customer = await this.$store.dispatch('customers/fetchCustomerByPhone', { organizationId: this.organizationId, phone: this.getPhoneOfActivity })  
        } finally {
          this.loadingCustomer = false
        }
      }
    }
  },
  async created () {
    this.title = this.$t("main-menu.agent-console.title");
    const organizationArray = [this.agent.organization_id]
    this.listenAgentsDashboard(this.agent.organization_id);
    this.listenQueuesDashboardArray(organizationArray)
    if(!localStorage.getItem('showChatDetails')){
      localStorage.setItem('showChatDetails', true)
    }
    if(!localStorage.getItem('enabledPendingNotification')){
      localStorage.setItem('enabledPendingNotification', true)
    }
    if(!localStorage.getItem('enabledNewMessageNotification')){
      localStorage.setItem('enabledNewMessageNotification', true)
    }
    this.showChatDetails = JSON.parse(localStorage.getItem('showChatDetails')) ?? true
    try {
      const status = await this.checkIfLogged();

      this.fetchBreaks();
      this.listenAgent();

      if (status) {
        await this.initJanus();
        if (!this.getLoggedInCallCenter()) {
          this.doCallCenterLogin();
        }

        this.canLoggin = true;
        this.loginClick = true;

        setTimeout(() => {
          if (this.getLogged()) {
            if (this.agent.call && !this.getLoggedInCallCenter()) {
              this.errorLoggin = true
            }

            if (this.agent.chat && !this.getLoggedInChatCenter()) {
              this.errorLogin = true;
            }
          }
        }, 5000);

      } else {
        this.fetchCanLoggin();
      }

    } catch (error) {
      console.log(error);
    }

    if (this.agent.chat) {
      this.$store.dispatch('departments/getBasic', { pagination: false, ["organization.id"]: this.agent.organization_id })
      setTimeout(() => {
        this.listenToInactiveChats();
      }, 5000);
    }
    EventBus.$on('newCustomer', async ({ customer }) => {
      this.customer = null
      this.loadingCustomer = true
      try {
        this.customer = await this.$store.dispatch('customers/fetchCustomerById', { organizationId: this.organizationId, customerId: customer })
      } finally {
        this.loadingCustomer = false
      }
    });
  },
  mounted () {
    this.$title = this.$t("main-menu.agent-console.title");
    setTimeout(() => {
      if (this.$route.query.t && !this.getLoggedStatus) {
        this.doAgentLogin();
      }
    }, 1000);
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.agent.mail) {
        vm.fetchTickets({ onlyAssigned: true, status: 'open', nopagination: true })
      }
    })
  }
};
</script>

<style scoped>
.agent-not-logged {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.agent-logged {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.agent-not-logged img {
  margin-bottom: calc(var(--column) * 2);
  width: 30em;
}

.agent-not-logged span:nth-child(2) {
  cursor: pointer;
  color: var(--blue);
}

.console {
  align-items: stretch;
  display: inline-flex;
  padding-top: var(--column);
  width: 100%;
  max-height: 91vh;
  gap: var(--column)
}

.console-header {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.console .box {
  background-color: white;
  border-radius: var(--radius);
  padding: var(--column);
}

.console .big-column {
  display: flex;
  flex-direction: column;
  margin: 0 var(--column);
  flex-grow: 1
}

.console .big-column>div {
  margin-bottom: var(--column);
}

.console .big-column>div:last-child {
  margin-bottom: 0;
}

.console .column {
  width: 25%;
}

.console .column:first-child {
  display: flex;
  flex-direction: column;
}

.console .column:last-child .box {
  margin-bottom: var(--column);
}

.console .last-column {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow-y: auto;
}

.agent-info {
  margin-bottom: var(--column);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.agent-info ul {
  display: inline-flex;
}

.agent-info ul li {
  list-style: none;
  font-size: 13px;
  margin-right: calc(var(--column) / 2);
  color: var(--blue-grey);
}

.tab-in-use {
  font-size: 13px;
}

.agent-info ul li.active {
  color: var(--green);
}

/** Esto es de OTRO COMPONENTE */
.call .fas,
.call .icon-keypad {
  background-color: white;
  border-radius: 50%;
  color: var(--blue-grey);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  transition: all 0.2s ease-in-out;
  margin-left: 10px;
}

.call .hangup {
  background-color: var(--red);
  color: white;
  transform: rotate(-134deg);
}

.console-buttons {
  display: inline-flex;
  gap: 8px;
}

.here:hover {
  cursor: pointer;
}

.agent-in-break-mobile {
  display: none;
}

.main-agent-content {
  flex: 1
}

.agent-activity {
  display: flex;
  flex-direction: column;
  min-width: 350px
}

.not-available {
  color: var(--orange)
}

.el-button+.el-button {
  margin: 0!important
}
.agent-console__side-bar-container{
  overflow: hidden;
  transition: all 2s;
  justify-content: flex-end;
}
.agent-console__side-bar-controls{
  padding: 10px;
  cursor: pointer;
}
.agent-console__side-bar-controls-container--open{
  display: flex;
  justify-content: center;
}
.agent-console__side-bar-controls-container{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: #fff;

}
</style>
