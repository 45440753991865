<template>
    <div class="chat" @dragenter="handleDropEnter" @dragover="handleDropEnter" @dragleave="handleDropLeave" @drop="handleDrop">
        <the-chat-header :title="title" v-if="displayHeader"></the-chat-header>
        <div class="loading" v-if="loading"  v-loading="loading" element-loading-background="rgba(0,0,0,0)"></div>
        <the-chat-messages-display
            :class="{
                'dragEnter' : dragEnter && displayTextarea
            }"
            :messages="messages"
            :unparsedMessages="unparsedMessages"
            :myself="myself"
            :user-type="userType"
            :room-id="roomId"
            @searchChatHistory="searchChatHistory"
            :unread-messages="unreadMessages"
            :reply="reply"/>
        <p v-if="usersWriting.length > 0 && displayTextarea" class="users-writing">{{ $tc('chat.writing', usersWriting , { users : usersWriting.join()}) }}</p>
        <slot name="chatTextArea">
        <the-chat-textarea
            ref="ChatTextarea"
            v-if="displayTextarea"
            @sendMessage="sendMessage"
            @isWriting="isWriting"
            @stopWriting="stopWriting"
            @uploadFiles="uploadFiles"
            :emojis="emojis"
            :room-id="roomId"
            :character-limit="characterLimit"
            :files-types="filesTypes"
            >
            <template v-slot:additionalButtons>
                <slot name="chatButtons">
                </slot>
            </template>
        </the-chat-textarea>
    </slot>
    </div>
</template>

<script>

import { mapActions } from 'vuex';
import TheChatHeader from "./TheChatHeader";
import TheChatMessagesDisplay from "./TheChatMessagesDisplay";
import TheChatTextarea from "./TheChatTextarea";
export default {
    name : "Chat",
    props : {
        emojis : {
            type : Boolean,
            default : false,
        },
        title : {
            type : String,
            required : false,
            default : '',
        },
        roomId : {
            type : String,
            required : true,
        },
        displayHeader : {
            type : Boolean,
            default : true
        },
        displayTextarea : {
            type : Boolean,
            default : true
        },
        path : {
            type : String,
            required : true,
        },
        myself : {
            type : Object,
            default : () => ({
                id : 0,
                name : '',
            })
        },
        userType : {
            type : String,
            required : false,
            default : 'normal'
        },
        newMessage : {
            required : false,
            default : 0
        },
        reply : {
            required : false,
            default : true
        },
        enableIsWriting : {
            required : false,
            default : true
        },
        characterLimit: {
            required: false,
            default: 0
        },
        filesTypes : {
            required: false,
            default: () => ([
                "image/jpeg",
                "image/jpg",
                "image/png",
                "image/gif",
                "video/mp4",
                "video/quicktime",
                "video/x-msvideo",
                "audio/mp3",
                "audio/wav",
                "audio/mpeg",
                "audio/wav",
                "audio/aac",
                "application/pdf",
                "application/msword",
                "text/plain",
                "application/zip",
                "application/x-rar-compressed",
                "application/x-7z-compressed",
                "application/vnd.ms-powerpoint",
                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "text/csv"
            ])
        }
    },
    data() {
        return {
            loading : false,
            writing : true,
            dragEnter: false
        }
    },
    components : {
        TheChatHeader,
        TheChatMessagesDisplay,
        TheChatTextarea
    },
    methods : {
        ...mapActions('_chats', [
            'uploadFileToStorage',
            'saveMessageFromChatroom' ,
            'fetchLastMessagesFromChatroomFromTimestamp',
            'userIsWriting'
        ]),
        handleDropEnter(e) {
            e.stopPropagation()
            e.preventDefault()
            this.dragEnter = true
        },
        handleDropLeave(e) {
            e.stopPropagation()
            e.preventDefault()
            this.dragEnter = false
        },
        handleDrop(e) {
            e.stopPropagation()
            e.preventDefault()
            this.handleDropLeave(e)
            this.$refs.ChatTextarea.onFilesChanges(e)
        },
        async sendMessage(text) {
            if (text !== "") {
                let message = { from : this.myself.name, date : new Date().getTime(), text : text, type : 'text', user_id : this.myself.id }
                if (this.chat.version) {
                    message = {
                        type: "text",
                        payload: {
                            text,
                        },
                        date: new Date().getTime(),
                        user_id: this.myself.id,
                        version: 2
                    }
                } 
                const id = await this.saveMessageFromChatroom({ roomId : this.roomId, message : message });
                this.$emit('sendedMessage', id)
            }
        },
        searchChatHistory(timestamp) {
            this.loading = true;
            this.fetchLastMessagesFromChatroomFromTimestamp({ roomId : this.roomId, timestamp : timestamp, callback : () => {
                this.loading = false;
            }});
        },
        uploadFiles(files) {
            files.map((file, index) => {
                const uploading = (value) => {
                    this.$refs.ChatTextarea.uploadingFiles(index, value);
                }
                this.uploadFileToStorage({ file: file, callback : uploading }).then(async (url) => {
                    let type = file.type.split("/").shift();

                    if (!["audio","video","image"].includes(type)) {
                        type = "file";
                    }

                    let message = {  
                        type,
                        from: this.myself.name,
                        date: new Date().getTime(),
                        text: url,
                        user_id: this.myself.id,
                        filename: file.name
                    }

                    if (this.chat.version) {
                        message = {
                            type,
                            payload: {
                                url,
                                filename: file.name,
                                contentType: file.type
                            },
                            date: new Date().getTime(),
                            user_id: this.myself.id,
                            version: 2
                        }
                    }

                    const id = await this.saveMessageFromChatroom({ roomId : this.roomId, message : message });
                    this.$emit('sendedMessage', id)
                });
            })
        },
        isWriting() {
            if (!this.enableIsWriting) {
                return;
            }

            this.$emit("userWriting")
            this.userIsWriting({ roomId : this.roomId})
            clearTimeout(this.isWriting)
            this.writing = setTimeout(() => {
                this.userIsWriting({ roomId : this.roomId, status : false})
            }, 2000)
        },
        stopWriting() {
            clearTimeout(this.writing)
        }
    },
    computed :{
        chat() {
            return this.$store.getters['_chats/getChatByRoomId'](this.roomId);
        },
        messages() {
            return (this.$store.getters['_chats/getChatByRoomId'](this.roomId)) ? this.$store.getters['_chats/getChatByRoomId'](this.roomId).messages : []
        },
        unparsedMessages() {
            return (this.$store.getters['_chats/getChatByRoomId'](this.roomId)) ? this.$store.getters['_chats/getChatByRoomId'](this.roomId).unparsedMessages : []
        },
        unreadMessages() {
            return this.$store.getters['_chats/getChatByRoomId'](this.roomId).unreadMessages
        },
        usersWriting() {
            const users = []
            const data = this.$store.getters['_chats/getChatByRoomId'](this.roomId).data

            if (!data) return users;

            if (data.userIsWriting) {
                Object.keys(this.$store.getters['_chats/getChatByRoomId'](this.roomId).data.userIsWriting).forEach(key => {
                    if (key !== this.myself.id && this.$store.getters['_chats/getChatByRoomId'](this.roomId).data.userIsWriting[key]) {
                       users.push(this.$store.getters['_chats/getChatByRoomId'](this.roomId).data.userIsWriting[key])
                    }
                });
            }
            
            return users
        },
    }
}
</script>

<style scoped>
    .chat {
        display: flex;
        flex-direction: column;
    }

    .loading {
        position: relative;
        margin-top: calc(var(--column) * 2);
    }

    p.users-writing {
        font-size: 14px;
        padding: 0 var(--column)
    }

    .dragEnter  {
        border: 2px solid green;
    }
    .chat__waiting-for-client-answer-container{
        height: 93px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>