<template>
  <div class="crud">
    <the-crud :title="title" :store="store" :columns="columns" :can-delete="true" :goBack="goBack" :init-query-params="{
        ['organization.id'] : organizationId
      }" :hidden-query-params="{ ['organization.id']: organizationId}" >
      <template v-slot:action-buttons="scope">
        <el-dropdown-item icon="fas fa-copy" @click.native="handleCopy(scope.row)">{{ $t('contact-me.chat-center.copy-callback') }}</el-dropdown-item>
        <el-dropdown-item :disabled="hasWhatsapp( scope.row )" icon="fab fa-whatsapp-square" @click.native="showModal( scope.row )">{{ $t('contact-me.chat-center.whatsapp.modal-open') }}</el-dropdown-item>
      </template>
    </the-crud>

    <el-dialog :visible.sync="dialogVisible" :title="getModalTitle( )" @close="resetValues">
      <p v-if="! whatsapp.gupshupApiKey">
        {{ $t("contact-me.chat-center.whatsapp.gupshup-key-missing") }}<br />
        <router-link :to="{ name: 'organizationsEdit', params: { id: getOrganizationId( ) }}">{{ $t("contact-me.chat-center.whatsapp.gupshup-key-edit") }}</router-link>
      </p>
      <el-form v-else label-position="top" :model="form">
        <el-form-item label="Number" :error="errors.number">
          <el-input v-model="whatsapp.number" :placeholder="$t('contact-me.chat-center.whatsapp.number')"></el-input>
        </el-form-item>
        <el-form-item label="Template" :error="errors.template">
          <el-select v-model="whatsapp.template" :placeholder="$t('contact-me.chat-center.whatsapp.template')">
            <el-option v-for="template in templates" :key="template.id" :label="template.elementName" :value="template.id" :id="template.id" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t("contact-me.chat-center.whatsapp.button-cancel") }}</el-button>
        <el-button type="primary" v-if="whatsapp.gupshupApiKey" @click="sendWhatsAppTest">{{ $t("contact-me.chat-center.whatsapp.button-send") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TheCrud from '@/components/crud/TheCrud'
import { mapActions } from 'vuex'


export default {
  data() {
    return {
      title: this.$t("contact-me.chat-center.department"),
      store: "departments",
      whatsapp: {
        number: null,
        template: null,
        gupshupApiKey: null,
        organizationId: null,
        departmentId: null
      },
      errors: {
        number: '',
        template: ''
      },
      dialogVisible: false,
      form: null,
      columns: [
        { index : 'id', label : '#id', sortable : true},
        { index : 'name', label : this.$t('configuration.organization.name') },
        { index: "chatProvider.name", label: this.$t('contact-me.chat-center.provider')},
        { index : 'callback', label : this.$t('contact-me.chat-center.callback'), width: 400 },
        { index : 'active', label : this.$t('configuration.users.is-active'), type : 'boolean'},
        { index : 'agents', label : this.$t('configuration.agents.title'), 'hide in table' : true, type : 'agents'},
      ]
    }
  },
  components: {
    TheCrud
  },
  computed:{
    templates( ){
      return this.$store.getters[`gupshup/alltemplates`];
    },
    organizationId() {
      return this.$store.getters["contactMe/getOrganizationId"];
    }
  },
  methods:{
    ...mapActions('gupshup', ['sendwhatsapptest']),
    handleCopy(department) {
      const url = `${process.env.VUE_APP_CHAT_ENGINE_HOST}/api/callback/${department.callback}`
      navigator.clipboard.writeText(url);
      this.$message({
        message: this.$t("contact-me.chat-center.callback-copied-success"),
        type: 'success'
      });
    },
    sendWhatsAppTest( ){
      this.errors.template = '';
      this.errors.number = '';

      if( ! this.whatsapp.template || ! this.whatsapp.number ){
        if( ! this.whatsapp.template ) this.errors.template = this.$t("contact-me.chat-center.whatsapp.missing-template");
        if( ! this.whatsapp.number ) this.errors.number = this.$t("contact-me.chat-center.whatsapp.missing-number");
        return;
      }
      const data = {
          type: 'test_whatsapp',
          departmentId: this.whatsapp.departmentId,
          number: this.whatsapp.number,
          template_id: this.whatsapp.template
      };
      this.sendwhatsapptest(data);
      this.dialogVisible = false;
      this.resetValues( );
    },
    showModal( row ){
      this.whatsapp.gupshupApiKey = row.organization.gupshupApiKey;
      this.whatsapp.organizationId = row.organization.id;
      this.whatsapp.departmentId = row.id;
      this.dialogVisible = true;
    },
    resetValues( ){
      this.errors.template = '';
      this.errors.number = '';
      this.whatsapp.departmentId = null,
      this.whatsapp.number = null;
      this.whatsapp.template = null
    },
    hasWhatsapp( row ){
      return ! ( row.whatsappNumber && row.whatsappNumber.toString( ).trim( ).length > 0 ) 
    },
    getOrganizationId( ){
      return new String( this.whatsapp.organizationId );
    },
    getModalTitle( ){
      return this.$t("contact-me.chat-center.whatsapp.test-title");
    },
    goBack() {
      this.$router.push({ name: "contact-me-chat-center"})
    },
  }

}
</script>