<template>
  <div class="crud">
    <the-crud :title="title" :store="store" :columns="columns" :can-delete="true" :goBack="goBack" :init-query-params="{
        ['organization.id'] : organizationId
      }" :hidden-query-params="{ ['organization.id']: organizationId}" >
    </the-crud>
  </div>
</template>

<script>
import TheCrud from '@/components/crud/TheCrud'

export default {
  data() {
    return {
      title: this.$t("contact-me.form.title"),
      store: "forms",      
      columns: [
        { index : 'id', label : '#id', sortable : true},
        { index : 'name', label : this.$t('configuration.organization.name') },
        { index : 'description', label : this.$t('common.description'), width: 400 },
      ]
    }
  },
  components: {
    TheCrud
  },
  computed:{
    organizationId() {
      return this.$store.getters["contactMe/getOrganizationId"];
    }
  },
  methods:{
    goBack() {
      this.$router.push({ name: "contact-me-chat-center"})
    },
  }

}
</script>