import ticketsapi from '@/api/tickets';

const getStaff = () => {
    if (localStorage.getItem('osticket_staff')) {
        return JSON.parse(localStorage.getItem('osticket_staff'));
    }
    return {};
}

const getDefaultEraserTicket = () => ({
    user_id: "",
    collaborators: [],
    source: "Email",
    sla_id: "",
    duedate: "",
    topic_id: "",
    dept_id: "",
    staff_id: "",
    notify: "all",
    priority: "",
    response : "",
    subject : "",
    files : [],
    add_forms_to_thread: false,
})

const state = {
    osticketToken : localStorage.getItem('osticket_token') || '',
    tickets : [],
    priorities : [
        {value: 'low', text: 'Low' },
        {value: 'normal', text: 'Normal' },
        {value: 'high', text: 'High'},
        {value: 'emergency', text: 'Emergency' }
    ],
    dataToUpadateTicket : {},
    ticketsView : [],
    staff : getStaff(),
    dataToCreateTicket : {},
    eraserTicket : getDefaultEraserTicket(),
    forms : [],
    agentMailDashboard: {},
    fetchTicketsQueryParams: {},
    advanceFilters: []
}

const getters = {
    getDataToCreateTicket : (state) => state.dataToCreateTicket,
    getMailCenterUser : (state) => state.staff,
    getAllTickets : (state) => state.tickets,
    getAllPriorities : (state) => state.priorities,
    getOsticketToken : (state) => state.osticketToken,
    getDataToUpdateTicket : (state) => state.dataToUpadateTicket,
    getTicketByNumber : (state) => (ticketNumber) => state.ticketsView.find((t) => t.number === ticketNumber),
    getEraserTicket : (state) => state.eraserTicket,
    getHelpTopicForms : (state) => state.forms,
    getAgentMailDashboard: (state) => state.agentMailDashboard,
    getTicketQueryParams: (state) => state.fetchTicketsQueryParams,
    getAdvanceFilters: (state) => state.advanceFilters
}

const mutations = {
    ADD_USER_TO_DATA_TO_CREATE_TICKET : (state, value) => state.dataToCreateTicket.users.push(value),
    SET_FORMS : (state, forms)  => state.forms = forms,
    SET_DATA_TO_CREATE_TICKET : (state, data) => state.dataToCreateTicket = data,
    SET_TICKETS : (state, tickets) => state.tickets = tickets,
    ADD_TICKET : (state, ticket) => state.tickets.push(ticket),
    SET_STAFF : (state, staff) => state.staff = staff,
    SET_OSTICKET_TOKEN : (state, token) => state.osticketToken = `Bearer ${token}`,
    SET_DATA_TO_UPDATE_TICKET : (state, data) => state.dataToUpadateTicket = data,
    ADD_TICKET_TO_VIEW(state, ticket) {
        if (!state.ticketsView.find((t) => t.number === ticket.number)) {
            state.ticketsView.push(ticket)
        } else {
            state.ticketsView = state.ticketsView.map((t) => t.number === ticket.number ? ticket : t)
        }
    },
    UPDATE_TICKET(state, data) {
        let ticket = state.tickets.find((ticket ) => ticket.ticket_id === data.ticket_id)
        if (ticket) {
            if (data.update === 'change-status') {
                ticket.status = state.dataToUpadateTicket.ticket_status.find((status) => status.id == data.id) 
            }

             if (data.update === 'assignee') {
                ticket.assigned = state.dataToUpadateTicket.staff.find((staff) => staff.staff_id === data.id);
            }

            if (data.update === 'change-priority') {
                let priotity = state.dataToUpadateTicket.ticket_priority.find((p) => p.priority_id === data.id);
                ticket.cdata.priority_info.priority = priotity.priority;
                ticket.cdata.priority_info.priority_id = priotity.priority_id;
                ticket.cdata.priority_info.priority_desc = priotity.priority_desc;
            }

            state.tickets = [...state.tickets.filter((ticket) => ticket.ticket_id !== data.ticket_id), ticket];
        }
    }, 
    ADD_THREAD_TO_TICKETS(state, {ticket_id, entry}) {
        let ticket = state.ticketsView.find((t) => t.ticket_id === ticket_id);
        
        if (ticket) {
            ticket.thread.entries.data.push(entry);
            state.ticketsView = [...state.ticketsView.filter((ticket) => ticket.ticket_id !== ticket_id), ticket];
        }
    },
    ADD_EVENT_TO_TICKET(state, { ticket_id, event }) {
        let ticket = state.ticketsView.find((t) => t.ticket_id === ticket_id);
        if (ticket) {
            if(event.timestamp){
                event.date = event.timestamp.date
            }
            ticket.thread.events.data.push(event);
            state.ticketsView = [...state.ticketsView.filter((ticket) => ticket.number !== ticket_id), ticket];
        }
    },
    SET_ERASER_TICKET : (state, value) => state.eraserTicket = value,
    SET_ERASER_TICKET_USER_ID : (state, value)=> state.eraserTicket.user_id = value,
    SET_ERASER_TICKET_BODY : (state, value)=> state.eraserTicket.response = value,
    UPDATE_TICKETS : (state, { ids, tickets }) => {
        state.tickets = state.tickets.filter(ticket => !ids.includes(ticket.ticket_id)).concat(tickets)
    },
    UPDATE_FORMS : (state, { ticketId, forms}) => {
        const ticket = state.ticketsView.find(ticket => ticket.ticket_id === ticketId)
        if (ticket) {
            ticket.forms.map(form => {
                const f = forms.find(f => f.id == form.id)

                if (f) {
                    form.entry_values = form.entry_values.map((entryValue) => {
                        const field = f.fields.find(f => f.id == entryValue.field.id)
                        if (field) {
                            entryValue.value = field.value
                        }
                        return entryValue;
                    })
                }
                return form
            })
        }
    },
    ADD_COLLABORATORS_TO_TICKET: (state, { ticketId, collaborator }) => {
        let ticket = state.ticketsView.find((t) => t.ticket_id === ticketId);
        if (ticket) {
            ticket.thread.collaborators.push(collaborator);
            state.ticketsView = [...state.ticketsView.filter((ticket) => ticket.number !== ticketId), ticket];
        }
    },
    REMOVE_COLLABORATOR_FROM_TICKET: (state, { ticketId, collaboratorId }) => {
        let ticket = state.ticketsView.find((t) => t.ticket_id === ticketId);
        if (ticket) {
            ticket.thread.collaborators = ticket.thread.collaborators.filter(user => user.id !== collaboratorId )
            state.ticketsView = [...state.ticketsView.filter((ticket) => ticket.number !== ticketId), ticket];
        }
    },
    SET_AGENT_MAIL_METRICS: (state, data) => state.agentMailDashboard = data,
    SET_TICKET_QUERY_PARAMS: (state, query) => state.fetchTicketsQueryParams = query,
    SET_ADVANCE_FILTERS: (state, filters) => state.advanceFilters = filters,
    SET_ENTRIES: (state, { entries, ticketId, initialize }) => {
        const index = state.ticketsView.findIndex((element) => element.ticket_id === ticketId)
        if(index > -1){
            if(initialize){
                state.ticketsView[index].thread.entries.data = [...entries.data];
            }else{
                state.ticketsView[index].thread.entries.data.push(...entries.data);
            }
            const fullEntries = state.ticketsView[index].thread.entries.data;
            state.ticketsView[index].thread.entries = entries;
            state.ticketsView[index].thread.entries.data = [...fullEntries];
        }
        
    },
    SET_EVENTS: (state, { events, ticketId, initialize }) => {
        const index = state.ticketsView.findIndex((element) => element.ticket_id === ticketId)
        if(index > -1){
            if(initialize){
                state.ticketsView[index].thread.events.data = [...events.data];
            }else{
                state.ticketsView[index].thread.events.data.push(...events.data);
            }
            
            const fullEvents = state.ticketsView[index].thread.events.data;
            
            state.ticketsView[index].thread.events = events;
            state.ticketsView[index].thread.events.data = [...fullEvents];
        }
        
    }
}

const actions = {
    doOsticketAuth({ commit }) {
        return new Promise((resolve, reject) => {
            ticketsapi.doAuth().then((response) => {
                commit('SET_OSTICKET_TOKEN', response.data.token);
                ticketsapi.getUserData().then((res) => {
                    commit('SET_STAFF', res.data);
                });
                localStorage.setItem('osticket_token', response.data.token)
                return resolve(response);
            }).catch((e) => {
                return reject(e)
            })
        });
    },
    fetchTickets({commit}, queryParams) {
        return new Promise((resolve, reject) => {
            commit('SET_TICKET_QUERY_PARAMS', queryParams)
            ticketsapi.fetchTickets(queryParams)
            .then((response) => {
                commit('SET_TICKETS', response.data.data ?? response.data);
                return resolve(response.data);
            })
            .catch((e) => {
                return reject(e)
            })
        })
    },
    fetchTicketByNumber({commit}, ticketNumber) {
        return new Promise((resolve) => {
            ticketsapi.fetchTicketByNumber(ticketNumber)
            .then((response) => {
                const ticket = response.data;
                commit('ADD_TICKET_TO_VIEW', ticket);
                return resolve(response.data);
            });
        })
    },
    fetchEntriesByTicketId({commit}, {ticketId, page, initialize}){
        return new Promise((resolve) => {
            ticketsapi.fetchEntriesByTicketId(ticketId, page)
            .then((response) => {
                const entries = response.data;
                commit('SET_ENTRIES', {entries, ticketId, initialize});
                return resolve(response.data);
            });
        })
    },
    fetchEventsByTicketId({commit}, {ticketId, page, initialize}){
        return new Promise((resolve) => {
            ticketsapi.fetchEventsByTicketId(ticketId, page, initialize)
            .then((response) => {
                const events = response.data;
                commit('SET_EVENTS', {events, ticketId, initialize});
                return resolve(response.data);
            });
        })
    },
    fetchDataToUpdateTheTicket({commit }) {
        return new Promise((resolve) => {
            ticketsapi.fetchDataToUpdateTheTicket()
            .then((response) => {
                commit('SET_DATA_TO_UPDATE_TICKET', response.data);
                return resolve(response.data);
            });
        });
    },
    updateTicket({commit}, { ticket_id, update, reason, id}) {
        return new Promise((resolve, reject) => {
            ticketsapi.updateTicket(ticket_id, update, id, reason)
            .then((response) => { 
                commit('UPDATE_TICKET', { ticket_id, update, id })

                console.log(reason)
                console.log(response)
                if (reason) {
                    commit('ADD_THREAD_TO_TICKETS', { ticket_id : ticket_id, entry : response.data.thread.threadEntry });
                    commit('ADD_EVENT_TO_TICKET', { ticket_id : ticket_id, event : response.data.event });
                }
                

                return resolve();
            })
            .catch((error) => {
                return reject(error)
            })
        });
    },
    answerTicket({commit}, {ticket_id, body, response, attachments, type = 'R', title = '', signature = "0"}) {
        return new Promise((resolve, reject) => {
            ticketsapi.answerTicket(ticket_id, body, response, attachments, type, title, signature)
            .then((response) => { 
                commit('ADD_THREAD_TO_TICKETS', { ticket_id : ticket_id, entry : response.data.threadEntry });
                return resolve(response);
            })
            .catch((error) => {
                return reject(error)
            })
        });
    },
    fetchDataToCreateANewTicket({ commit }) {
        return ticketsapi.fetchDataToCreateANewTicket()
        .then((response) => {
            commit('SET_DATA_TO_CREATE_TICKET', response.data);
        })
    },
    createNewTicket({ commit }, form) {
        return new Promise((resolve, reject) => {
            ticketsapi.createNewTicket(form)
            .then((response) => {
                if (response) {
                    commit('SET_ERASER_TICKET', getDefaultEraserTicket())
                    if (response.data.ticket){ 
                        commit('ADD_TICKET', response.data.ticket);
                    }
                    return resolve(response.data)
                }
                return resolve()
            })
            .catch((error) => {
                return reject(error)
            })
        })
    },
    setEraserTicket({ commit }, form) {
        commit('SET_ERASER_TICKET', form)
    },
    setEraserTicketUserId({ commit }, value) {
        commit('SET_ERASER_TICKET_USER_ID', value)
    },
    setEraserTicketResponse({ commit }, value) {
        commit('SET_ERASER_TICKET_BODY', value)
    },
    cleanTicket({commit}) {
        commit('SET_ERASER_TICKET', getDefaultEraserTicket())
    },
    multipleUpdate({commit}, data) {
        return ticketsapi.multipleUpdate(data).then((response) => {
            commit('UPDATE_TICKETS', { ids : data.ids, tickets : response.data.tickets })
        })
    },
    fetchFormsByHelpTopic({commit}, helpTopic) {
        return ticketsapi.fetchFormsByHelpTopic(helpTopic).then((response) => {
            commit('SET_FORMS', response.data.data)
        })
    },
    updateFormFields({commit}, {ticketId, data}) {
        return ticketsapi.updateFormsFields(ticketId, data).then((response) => {
            commit('UPDATE_FORMS', { ticketId, forms : response.data.forms})
        })
    },
    addCollaboratorsToTicket({commit}, {ticketId, userId }) {
        return ticketsapi.addCollaboratorsToTicket(ticketId, userId )
        .then((response) => {
            commit('ADD_COLLABORATORS_TO_TICKET', { ticketId, collaborator : response.data.data})
        })
    },
    removeCollaboratorFromTicket({ commit}, {ticketId, collaboratorId}) {
        return ticketsapi.removeCollaboratorFromTicket(ticketId, collaboratorId)
        .then(() => {
            commit('REMOVE_COLLABORATOR_FROM_TICKET', { ticketId, collaboratorId })
        })
    },
    fetchAgentMailMetrics({ commit }) {
        return ticketsapi.fetchAgentMailMetrics().then((response) => {
            commit('SET_AGENT_MAIL_METRICS', response.data )
        }) 
    },
    fetchAdvanceFiltersFields({ commit }) {
        return ticketsapi.fetchAdvanceFiltersFields().then((response) => {
            commit('SET_ADVANCE_FILTERS', response.data.data)
            return response.data
        }) 
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}