
function getName(value) {
  if (/^sms whatsapp:\+/i.test(value)) {
    value = value.replace(/sms whatsapp:/i, '');
  }
  return value;
}

/**
 *
 * @param {string} key
 * @param {object} data
 *
 * @return object
 */
function buildChatItem(key, data) {
  return {
    key: key,
    type: 'chat',
    activeItem: false,
    unsubscribe: null,
    active: ('active' in data && data) ? data.active : false,
    title: ('name' in data) ? `${getName(data.name)} - ${data.dep_name}` : `Unknow`,
    date: !data.created_chat_engine ? new Date(data.startedAt * 1000) : new Date(data.startedAt),
    data: {
      roomId: key,
      lastMessage: '',
      unreadMessages: 0,
      from: ('from' in data) ? data.from : 'lhc',
      status: (data.status) ? data.status : 'active',
      chatId: data.id,
      tags: data.chatDispositions ?? [],
      chatDispositions: data.chatDispositions ?? [],
      waitingForClientAnswer: data.waitingForClientAnswer ?? null,
      dept_id: data.dep_id,
      waitingForAgentAnswer: data.waitingForAgentAnswer ?? false
    },
    clientinfo: (data.clientInfo) ? data.clientInfo : [],
    integrations : data.integrations || null,
    createdWitChatEngine: data.created_chat_engine ?? false,
    version : data.version ?? 0
  }
}

/**
 * Check if the status of the chat is 1 and check if
 * the user_id is the same that the agent.chat_center_id
 *
 * @param {*} chat
 * @param {*} agent
 *
 * @return bool
 */
function canListenToTheChat(chat, agent) {
  if (chat === undefined) {
    return true;
  }
  return chat.status === 1 && chat.user_id === agent.id;
}

const actions = {
  listenNewAgentsChats({ rootGetters, dispatch, commit }) {
    const user = rootGetters.getUser;
    const { agentActivityTimeout } = rootGetters.getAgent;
    const firebase = rootGetters.getFirebase;

    const date = new Date();
    date.setMinutes(date.getMinutes() - agentActivityTimeout);

    firebase.database().ref(`chat_center/${user.uid}`).orderByChild('startedAt').startAt(date.getTime()).on('child_added', async (snap) => {
      if (snap.val() === null || Object.keys(snap.val()).length === 1 && ('active' in snap.val())) {
        commit('REMOVE_ACTIVITY', snap.key, { root: true });
        return;
      }

      const getLogged = rootGetters.getLogged
      const getLoggedInChatCenter = rootGetters.getLoggedInChatCenter
      const chatItem = buildChatItem(snap.key, snap.val());

      const chatAdded = new Date();
      chatAdded.setMinutes(chatAdded.getMinutes() - 1);
      
      if (getLoggedInChatCenter && getLogged) {
        await snap.ref.update({
          isChatAttended: true
        })
      }

      firebase.database().ref(`chat_center/${user.uid}`).child(snap.key).on('value', (snap) => {
        const agent = rootGetters.getAgent;
        if (!snap.val()) {
          return;
        }

        const { data = { unreadMessages: 0 }} = rootGetters.getActivityByKey(snap.key) ?? {};
        const chatItem = buildChatItem(snap.key, snap.val());
        chatItem.data.unreadMessages = data.unreadMessages
        const chat = { name: chatItem.name, path: rootGetters.getChatPath, roomId: chatItem.key, myself: { id: agent.chat_center_id }, version: chatItem.version };

        const lastRedeableMessage = (snap.val().lastRedeableMessage) ? snap.val().lastRedeableMessage : null;
        
        dispatch('_chats/initChatroom', chat, { root: true }).then(() => {
          if (rootGetters.getPendingChatAcept) {
            if (rootGetters.getPendingChatAcept.roomId === chatItem.data.roomId && (chatItem.active || rootGetters.getActivityByKey(chatItem.key))) {
              commit('SET_PENDING_CHAT_ACCEPT', false, { root : true });
              commit('FORCE_ACTIVE_ITEM', chatItem.key, {root : true});
              if (rootGetters.getShowAcceptChatWindow) {
                commit('TOGGLE_SHOW_ACCEPT_CHAT_WINDOW','', {root: true});
              }
            }
          }

          dispatch('_chats/listenDataFromChatroom', {
            roomId: chatItem.key,
            callback: (data) => {
              // This will only happens when the chat is already in the state
              if (rootGetters.getActivityByKey(chatItem.key)) {
                if (!data) {
                  return;
                }
                chatItem.data.dept_id = data.dep_id
                chatItem.data.chatClassifications = data.chatClassifications ?? []
                chatItem.data.tags = data.chatDispositions ?? []
                chatItem.active = canListenToTheChat(data, agent); 
                chatItem.version = data.version ?? 0
                chatItem.internalNotes = []
                dispatch("_chats/listenInternalNotesFromChatroom", {roomId: chatItem.key}, {root: true})
                chatItem.data.waitingForAgentAnswer = data.waitingForAgentAnswer ?? false
                if (lastRedeableMessage) {
                  dispatch('_chats/fetchLastMessagesFromChatroomFromTimestamp', { roomId: chatItem.data.roomId, timestamp: lastRedeableMessage, resetState: true }, { root: true })
                } else {
                  dispatch('_chats/fetchAllMessagesFromChatroom', { roomId: chatItem.data.roomId }, { root: true });
                }

                const canListen = canListenToTheChat(data, agent)

                if (!canListen) {
                  chatItem.active = false;
                  dispatch('_chats/stopListenMessagesFromChatroom', { roomId: chatItem.data.roomId }, { root: true })
                } else {
                  chatItem.name = data.nick
                  chatItem.title = data.nick +" - "+ data.dep_name
                  dispatch('_chats/listenMessagesFromChatroom', {
                    roomId: chatItem.data.roomId,
                    newMessageCallback: (message) => {
                      const item = rootGetters.getActivityByKey(chatItem.key);
                      item.active = true;
                      item.data.lastMessage = message;
                      item.date = new Date(message.date)
                      // TODO: Cambiar las fechas por firestore
                      if (chatAdded < message.date && message.user.id === 0) {
                        item.data.unreadMessages++;
                      }
                      commit('UPDATE_ACTIVITY', item, { root: true });
                    }
                  }, { root: true });
                }
                commit('UPDATE_ACTIVITY', chatItem, { root: true });
              }
            }
          }, { root: true });
        });
      });
      commit('ADD_ACTIVITY', chatItem, { root: true });
    });

    firebase.database().ref(`chat_center/${user.uid}`).on('child_removed', (snap) => {
      commit('REMOVE_ACTIVITY', snap.key, { root: true });
    });
  },
  createAction({ rootGetters }, action ) {
    const user = rootGetters.getUser;
    const firebase = rootGetters.getFirebase;
    return firebase.database().ref(`chat_engine_actions`).push({...action, uid: user.uid, host: process.env.VUE_APP_CHAT_ENGINE_HOST })
  },
  listenToChatEngineAction({ rootGetters }, { key, callback }) {
    const firebase = rootGetters.getFirebase;
    firebase.database().ref(`chat_engine_actions`).child(key).on('value', (snap) => {
      if (snap) {
        callback(snap.val());
      }
    })
  },
  removeAction({ rootGetters }, actionKey ) {
    const firebase = rootGetters.getFirebase;
    return firebase.database().ref(`chat_engine_actions`).child(actionKey).remove();
  },
  setChatDispositions( { rootGetters }, { roomId, chatDispositions }) {
    const firebase = rootGetters.getFirebase;
    const organizationId = rootGetters.getAgent.organization_id;
    return firebase.firestore().doc(`livehelperchat/${organizationId}/chats/${roomId}`).update({ chatDispositions })
  },
  setChatClassification({ rootGetters} , { roomId, chatClassifications }) {
    const firebase = rootGetters.getFirebase
    const organizationId = rootGetters.getAgent.organization_id
    return firebase.firestore().doc(`livehelperchat/${organizationId}/chats/${roomId}`).update({ chatClassifications })
  }
}

export default {
  actions,
  namespaced: true,
}