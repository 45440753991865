<template>
	<Box :title="$t('agent-console.customer')" :isStatic='isStatic'>
		<!-- <template #dropdown> -->
		<!-- <el-dropdown size="medium" trigger="click" v-if="haveOptions">
					<span class="el-dropdown-link">
						<i class="fas fa-ellipsis-v"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						
						<el-dropdown-item @click.native="handleViewConversations" v-if="haveConversations">{{ $t("customer.view-chats") }}</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown> -->
		<!-- </template> -->
		<ul class="properties">
			<li v-for="property in propertiesList" :key="property.key">
				<div>
					<span class="label">
						<i :class="getIcon(property)"></i>
						{{ property.label ? property.label : getLabel(property.key) }}
					</span>
					<span class="value" v-if="property.value">{{ property.value }}</span>
					<span class="value" v-else style="font-size:13px; color: var(--blue-grey);">{{ $t('customer.set-value', {
						label:
							property.label }) }}</span>
				</div>
				<div>
					<button v-if="property.editable" @click="showEditProperty(property)">
						<i class="fas fa-pencil-alt"></i>
					</button>
					<el-popover placement="top" width="250" v-if="property.deletable">
						<p>{{ $t("customer.are-you-sure-to-delete-this-property") }}</p>
						<div style="text-align: right; margin: 0">
							<el-button size="mini" type="text">{{ $t("common.no") }}</el-button>
							<el-button type="primary" size="mini" @click="handleDeleteProperty(property)">{{ $t("common.yes")
								}}</el-button>
						</div>
						<button slot="reference">
							<i class="fas fa-trash"></i>
						</button>
					</el-popover>
				</div>
			</li>
			<el-button icon="fas fa-plus" size="small" type="text" @click="showNewProperty">{{ $t("customer.add-property")
				}}</el-button>
		</ul>
		<el-button size="small" type="text" @click="showAllProperties = !showAllProperties">{{ !showAllProperties ?
			$t("customer.view-all")
			: $t("customer.hide") }}</el-button>
		<el-button size="small" type="text" @click.native="handleViewConversations" >{{
			$t("customer.view-chats") }}
		</el-button>
		<el-dialog append-to-body :title="editingPropertyKey ? $t('customer.edit-property') : $t('customer.add-property')"
			:visible.sync="showDialogProperty" @closed="resetProperty" v-loading="submittingFormProperty"
			:close-on-press-escape="!submittingFormProperty" :close-on-click-modal="!submittingFormProperty">
			<el-form :model="property" :rules="formPropertyRules" ref="formPropertyRef">
				<el-form-item :label="$t('customer.property.label')" prop="label" v-if="!property.isInput">
					<el-input :placeholder="$t('customer.property.label')" v-model="property.label">
						<el-select v-model="property.type" slot="prepend" style="width: 100px;">
							<el-option v-for="property in selectProperties" :key="property.value" :label="property.label"
								:value="property.value">
								<span>
									<i style="color: #8492a6; font-size: 13px; margin-right: 4px;" :class="property.icon" />
									<span>{{ property.label }}</span>
								</span>
							</el-option>
						</el-select>
					</el-input>
				</el-form-item>
				<el-form-item :label="!property.isInput ? $t('customer.property.value') : property.label" prop="value">
					<el-input v-model="property.value" v-if="property.type !== 'select'"></el-input>
					<el-select v-model="property.value" v-else filterable style="width: 100%">
						<el-option v-for="option in property.options" :label="option.label" :key="option.value"
							:value="option.value">
							<span>
								<img :src="option.image" width="15" v-if="option.label" lazy style="margin-right: 8px">
								<span>{{ option.label }}</span>
							</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('customer.property.can-search-by', { search: property.label })">
					<el-switch v-model="property.searchable" :disabled="isCustomProperty(property.key)"></el-switch>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="showDialogProperty = false">
					{{ $t("common.cancel") }}
				</el-button>
				<el-button type="primary" size="small" @click="handleSubmit">
					{{ $t("common.save") }}
				</el-button>
			</span>
		</el-dialog>
		<TheConversationsDialog :name="clientName" ref="conversationDialog" :customer-id="customerId"/>
	</Box>
</template>

<script>
import Box from './Box.vue'
import http from '@/api/reports'
import TheConversationsDialog from './TheConversationsDialog.vue'
export default {
	props: {
		client: {
			required: true
		},
		isStatic: {
			required: false,
			default: false
		}
	},
	components: {
		Box,
		TheConversationsDialog
	},
	data () {
		return {
			chats: [],
			customer: this.client,
			showDialogProperty: false,
			submittingFormProperty: false,
			property: {
				type: 'text',
				value: '',
				label: '',
				key: '',
				editable: true,
				deletable: true,
			},
			formPropertyRules: {
				label: [
					{ required: true, trigger: 'blur', message: this.$t("customer.property-rules.label-is-required") },
				],
				value: [
					{ required: true, trigger: 'blur', message: this.$t("customer.property-rules.value-is-required") },
				],
			},
			showAllProperties: false,
			editingPropertyKey: null,
		}
	},
	computed: {
		list () {
			return this.customer.values.map(value => value)
				.sort((a, b) => {
					const inputA = this.$store.getters["customers/getFieldFromFormByKey"](a.key)
					const inputB = this.$store.getters["customers/getFieldFromFormByKey"](b.key)
					const orderA = inputA ? inputA.order : Infinity
					const orderB = inputB ? inputB.order : Infinity
					return orderA - orderB
				})
		},
		customerId () {
			return this.customer.id
		},
		propertiesList () {
			return !this.showAllProperties ? this.list.slice(0, 3) : this.list
		},
		clientName () {
			return this.customer.values.filter(item => item.key === 'name' || item.key === 'lastname').map(item => item.value).join(' ')
		},
		organizationId () {
			return this.$store.getters["getAgent"].organization_id.toString()
		},
		typesOfProperties () {
			return this.$store.getters["customers/getTypesOfProperties"]
		},
		selectProperties () {
			return this.typesOfProperties.filter(property => property.value !== 'select' || this.editingPropertyKey)
		},
		haveConversations () {
			return this.chats.length > 0
		},
		haveOptions () {
			return this.haveConversations
		},
	},
	methods: {
		handleSubmit () {
			this.$refs.formPropertyRef.validate(async (valid) => {
				if (valid) {
					this.submittingFormProperty = true
					if (!this.editingPropertyKey) {
						this.property.key = this.property.label.toLowerCase().replaceAll(' ', '_')
						await this.$store.dispatch("customers/addPropertyToCustomer", { organizationId: this.organizationId, customer: this.customer, payload: this.property })
					} else {
						const { key, label, type, value, searchable } = this.property
						const payload = { key, label, type, value, searchable }
						await this.$store.dispatch("customers/editPropertyToCustomer", { organizationId: this.organizationId, customer: this.customer, payload })
					}
					await this.fetchClient()
					this.submittingFormProperty = false
					this.showDialogProperty = false
					this.$message({ message: this.$t('customer.the-property-was-saved'), type: 'success' })
				}
			})
		},
		resetProperty () {
			this.property = {
				type: 'text',
				value: '',
				label: '',
				editable: true,
				deletable: true
			}
		},
		getIcon (property) {
			return this.typesOfProperties.find(item => item.value === property.type)?.icon
		},
		getLabel (key) {
			const input = this.$store.getters["customers/getFieldFromFormByKey"](key)
			return input ? input.label : ""
		},
		handleViewConversations () {
			this.$refs.conversationDialog.show()
		},
		async handleDeleteProperty ({ key }) {
			const customer = this.customer
			await this.$store.dispatch("customers/deletePropertyOfCustomer", { organizationId: this.organizationId, key, customer })
			await this.fetchClient()
			this.$message({ message: this.$t("customer.the-property-was-deleted"), type: 'success' })
		},
		async fetchClient () {
			this.customer = await this.$store.dispatch("customers/fetchCustomerById", { organizationId: this.organizationId, customerId: this.customerId })
		},
		showEditProperty (property) {
			const input = this.$store.getters["customers/getFieldFromFormByKey"](property.key)
			this.property = { ...property, ...input, isInput: Boolean(input) }
			this.editingPropertyKey = property.key
			this.showDialogProperty = true
		},
		showNewProperty () {
			this.resetProperty()
			this.editingPropertyKey = null
			this.showDialogProperty = true
		},
		async fetchChats () {
			const { data } = await http.getChatsFromClient({ clientId: this.customerId })
			this.chats = data['hydra:member']
		},
		isCustomProperty (key) {
			return this.$store.getters["customers/getFieldFromFormByKey"](key) ? true : false
		}
	},
	watch: {
		client (newValue) {
			this.customer = newValue
		//	this.fetchChats()
		}
	},
	created () {
		//this.fetchChats()
	}
}
</script>

<style scoped>
h3 {
	font-size: 16px;
}

ul {
	list-style: none;
}

.properties li {
	font-size: 14px;
	margin-bottom: 4px;
	display: inline-flex;
	width: 100%;
	cursor: pointer;
}

.properties li>div:first-child {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.properties li span:first-child {
	color: #606266;
}

i {
	margin-right: 2px;
}

.properties li i {
	font-size: 12px;
}


.properties li button {
	outline: none;
	background: none;
	border: none;
	color: #606266;
	margin-left: 4px;
	display: none;
}

.properties li:hover button {
	display: block;
}
</style>
