import { render, staticRenderFns } from "./TheAgentCard.vue?vue&type=template&id=444ef282&scoped=true&class=prueba%20test&"
import script from "./TheAgentCard.vue?vue&type=script&lang=js&"
export * from "./TheAgentCard.vue?vue&type=script&lang=js&"
import style0 from "./TheAgentCard.vue?vue&type=style&index=0&id=444ef282&prod&lang=css&"
import style1 from "./TheAgentCard.vue?vue&type=style&index=1&id=444ef282&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "444ef282",
  null
  
)

export default component.exports