import createStore from "../helper"
import api from '../../api/http'

const store = createStore("departments");

const actions = {
  getBasic({ commit }, params ) {
    return api.get("/departments/basic", { params }).then(({ data }) => {
      commit('SET_DATA', data['hydra:member'])
    });
  }
}

store.actions = { ...store.actions, ...actions }

export default store;