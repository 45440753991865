<template>
  <el-dialog :visible.sync="visible" :title="title" width="80%" @closed="resetValues" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body>
    <section v-loading="loadingChats">
      <ul>
        <li v-for="chat in chatsList" :key="chat.room_id" @click="handleActiveChat(chat)" :class="{ 'active' : chat.room_id === activeChat}">
          <div :class="['item-type', chat.type]">
            <i :class="getIcon(chat)" />
          </div>
          {{ chat.created_at.value | ticketDate(true)}}
        </li>
      </ul>
      <the-chat-messages-display 
        v-if="activeChat"
        class="conversation"
        :messages="messages"
        :unparsedMessages="[]"
        :myself="myself"
        :user-type="chat.type"
        room-id="''"
        :unread-messages="0"
        :reply="false"
      />
      <div v-else class="messages">
        <div>
          <img src="@/img/undraw_begin_chat_re_v0lw.svg" alt="Image of a person with a bubble chat" width="300"/>
          <p>{{ $t(("customer.click-on-a-chat-to-view-the-messages")) }}</p>
        </div>
      </div>
    </section>
  </el-dialog>
</template>

<script>
import http from '@/api/reports'
import { parseMessageFromFirebase } from '@/helpers/chat'
import TheChatMessagesDisplay from "@/components/chat/TheChatMessagesDisplay";
export default {
  props: {
    name: {
      type: String,
      default: 'Unknown'
    },
    customerId: {
      required: true
    }
  },
  data () {
    return {
      visible: false,
      loadingChats: false,
      error: '',
      activeChat: null,
      messages: [],
      chats: []
    }
  },
  methods: {
    async fetchMessages() {
      this.loadingChats = true
      try {
        const { data } = await http.getMessagesOfChat(this.activeChat)
        this.messages = data['hydra:member']
          .map((message) => ({...parseMessageFromFirebase(message), date: parseInt(message.date), code: message.type === 'system' ? 1 : undefined }))
          .map(message => ({ ...message, user: { id: parseInt(message.user.id) }}))
          .sort((a, b) => a.date - b.date)
      } catch {
        this.error = 'There was an error trying to get the chats'
      } finally {
        this.loadingChats = false
      }
    },
    getIcon (chat) {
      return chat.type === 'web' ? 'fas fa-comment' : `fab fa-${chat.type}`
    },
    show() {
      this.visible = true
    },
    handleActiveChat(chat) {
      this.loadingChats =  true
      this.activeChat = chat.room_id
      this.messages =  []
      this.fetchMessages()
    },
    resetValues() {
      this.visible =  false
      this.error =  ''
      this.activeChat =  null
      this.messages =  []
    },
    async fetchChats () {
      this.loadingChats = true
      const { data } = await http.getChatsFromClient({ clientId: this.customerId })
      this.chats = data['hydra:member']
      this.loadingChats = false
    }
  },
  computed: {
    title() {
      return this.$t("customer.chat-with", { name: this.name })
    },
    myself() {
      return { id: parseInt(this.chat?.user_id ?? 0) }
    },
    chat() {
      return this.chats.find(chat => chat.room_id === this.activeChat)
    },
    chatsList() {
      return [...this.chats].sort((a, b) => new Date(b.created_at.value).getTime() - new Date(a.created_at.value).getTime() )
    }
  },
  components: {
    TheChatMessagesDisplay
  },
  watch : {
    visible: function (newValue) {
        if (newValue) {
          this.fetchChats()
        }
    }
  }
}
</script>

<style scoped>

section {
  display: inline-flex;
  width: 100%;
  min-height: 60vh
}

ul {
  list-style: none;
  width: 20%;
  overflow-y: auto;
  border-right: 1px solid#dcdfe6;
  padding-left: 4px;
}

li {
  border-bottom: 1px solid#dcdfe6;
  padding: var(--column)
}

li.active {
    background-color: var(--light-blue);
}

.item-type {
  border-radius: var(--radius);
  color: white;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  width: 32px;
  font-size: 14px;
  padding: 0 10px;
  margin-right: 4px;
}

.item-type.web {
  background-color: var(--blue);
}

.item-type.whatsapp {
  background-color: var(--whatsapp-green);
}

.item-type.instagram {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

.item-type.facebook {
  background-color: #3b5998
}

ul,
.conversation {
  max-height: 60vh
}

.messages {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messages img {
  margin-bottom: var(--column);
}

.messages p {
  text-align: center;
}

</style>
