<!-- eslint-disable vue/no-unused-components -->
<template>
  <div id="chat-test" class="main-content-agent box" :class="{
    'show-chat' : type !== 'call'
  }">
    <el-button type="text" class="go-back-button mt-0" @click="setActiveItem(getActiveItem.key)"><i class="fas fa-arrow-left"></i> Mostrar mi actividad</el-button>
    <div class="content">
      <div class="header">
        <p class="title mb-0">{{ titleMessage }}</p>
        <div>
          <the-chat-buttons v-if="type == 'chat' && !(wasTransfered && !active)" :chat-id="chatId" :room-id="chatRoomId" :active="isAgentLogged" :user-type="activeClient.data.from" @showTransferDrawer="showTransferDrawer" />
          <chronometer v-if="type == 'call'" :timestamp="date" :timestampEnded="data.ended"></chronometer>
        </div>
      </div>
      <div v-if="type === 'call'" class="content">
        <div v-if="data.script" class="script">
          <p v-html="scriptParsed"></p>
        </div>
        <el-tabs v-model="activeForm" style="flex:1; overflow-x:auto" v-if="Object.keys(forms).length > 0">
          <el-tab-pane v-for="form in forms" :key="form.key" :label="form.name | utf8" :name="form.position + ''">
            <Form :form="{ ...form, idCall, callKey }" @submit="submitForm" :path="path + '/' + form.key"></Form>
          </el-tab-pane>
        </el-tabs>
        <div v-if="Object.keys(forms).length === 0" style="flex: 1 1 0%; align-items: center; width: 100%; display: flex; justify-content: space-evenly;">
          <img src="@/img/undraw_phone_call_re_hx6a.svg" style="width: 30em;" v-if="getIsInCall" />
          <img src="@/img/undraw_join_re_w1lh.svg" style="width: 20em;" v-else />
        </div>
      </div>
      <Chat ref="chat" v-else :room-id="chatRoomId" :display-header="false" :display-textarea="showChatTextArea" :key="chatRoomId"
        :myself="agent" :path="getChatPath()" :user-type="activeClient.data.from" :emojis="false" :reply="false"
        class="console-chat" :enable-is-writing="false" @click.native="cleanNotReadedNotification" @sendedMessage="handleSendedMessage"
        :character-limit="activeClient.data.from === 'whatsapp' ? 1600 : 0"
        :filesTypes="['image/jpg','image/jpeg', 'image/png', 'audio/mp3', 'audio/ogg', 'audio/amr', 'video/mp4', 'application/pdf', 'audio/mpeg']"
        >
        <template v-slot:chatButtons>
          <i class="fas fa-bolt" @click="handleQuickResponseTemplateDialog"></i>
        </template>
        <template v-slot:chatTextArea v-if="!showChatTextArea">
          <div class="chat__waiting-for-client-answer-container" v-if="activeClient.data['waitingForClientAnswer']">
            <span>{{ $t('chat.waiting-for-client-answer') }}</span>
          </div>
        </template>
      </Chat>
    </div>
    <TheQuickResponseTemplateDialog @selectQuickResponseTemplate="selectQuickResponseTemplate" @closeDialog="isQuickResponseTemplateDialogOpen=false" :isExternalOpen="isQuickResponseTemplateDialogOpen"></TheQuickResponseTemplateDialog>
  </div>
  
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Chat from "@/components/chat/Chat";
import Form from "@/components/agent-console/Form";
import Chronometer from "@/components/common/Chronometer";
import TheChatButtons from "@/components/agent-console/TheChatButtons";
import TheQuickResponseTemplateDialog from '@/components/agent-console/TheQuickResponseTemplateDialog.vue';

export default {
  name: "TheMainAgentContent",
  props: {
    type: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    date: {
      required: false,
    },
    active: {
      required: false,
      default: true,
      type: Boolean,
    },
    data: {
      required: false,
    },
    forms: {
      required: false,
      type: Object,
    },
    idCall: {
      required: false,
    },
    callKey: {
      required: false,
    },
    activeClient: {
      required: false,
    },
  },
  data() {
    return {
      activeForm: "0",
      isQuickResponseTemplateDialogOpen: false

    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Chat,
    // eslint-disable-next-line vue/no-unused-components
    Form,
    // eslint-disable-next-line vue/no-unused-components
    Chronometer,
    // eslint-disable-next-line vue/no-unused-components
    TheChatButtons,
    TheQuickResponseTemplateDialog
  },
  methods: {
    ...mapGetters(["getAgent", "getChatPath","getLoggedInChatCenter"]),
    ...mapActions(["resetUnreadMesages","setActiveItem", "saveForm" , "setFormStatusToActive"]),
    selectQuickResponseTemplate(payload){
      this.$refs.chat.$refs.ChatTextarea.message = payload
      
    },
    handleQuickResponseTemplateDialog(){
      this.isQuickResponseTemplateDialogOpen = true;
    },
    showTransferDrawer(data) {
      this.$emit("showTransferDrawer", data);
    },
    cleanNotReadedNotification() {
      this.resetUnreadMesages(this.chatRoomId);
    },
    submitForm(form) {
      const data = {
        id_call : form.idCall,
        form : form.inputs,
        call_type : form.type,
        call_key : form.callKey,
        form_key : form.key
      }
      this.saveForm(data).then(() => {
        this.setFormStatusToActive(this.path + '/' +  data.form_key);
      });
    },
    handleSendedMessage(id) {
      if (this.activeClient.type === 'chat' && this.activeClient.data.from === 'whatsapp') {
        this.$store.dispatch('_chats/listenToMessage', { roomId: this.chatRoomId, messageId: id })
      }
    }
  },
  computed: {
    ...mapGetters(['getActiveItem','getIsInCall']),
    chat() {
        return this.$store.getters['_chats/getChatByRoomId'](this.data.roomId);
    },
    scriptParsed: function () {
      if (!this.data.script) {
        return "";
      }

      return this.data.script
        .replace(/<style type="text\/css">.*<\/style>/g, "")
        .replace(/<style type="text\/css">(.*\t*\n*)+<\/style>/, "")
        .replace("background: #FFF;", "");
    },
    chatRoomId() {
      return this.data.roomId;
    },
    chatId() {
      return this.data.chatId;
    },
    isAgentLogged() {
      if (this.active && this.agent.id && this.getLoggedInChatCenter()) {
        return true;
      }
      return false;
    },

    showChatTextArea() {
      return this.isAgentLogged && !this.activeClient.data.waitingForClientAnswer
    },
    agent() {
      return {
        id: (this.getLoggedInChatCenter()) ? this.getAgent().chat_center_id : 0,
        name: this.getAgent().name,
        organization_id : this.getAgent().organization_id
      };
    },
    wasTransfered(){
      //return this.chat.data.wasTransfered ?? false;
      return false
    },
    path() {
      return `organization/${this.agent.organization_id}/calls/${this.callKey}/forms`
    },
    titleMessage() {
      if (this.type === 'call') {
        if (this.active) {
          return this.$t("agent-console.you-are-in-call-with", { callerid: this.title })
        }

        if (this.data.status && this.data.status === 'not-connected') {
          return this.$t("agent-console.the-call-failed", { callerid: this.title })
        }

        return this.$t("agent-console.you-were-on-call-with", { callerid: this.title })
      }
      return this.title;
    }
  },
};
</script>

<style scoped>
.main-content-agent {
  height: 100%;
}

.show-chat {
  padding: 0!important
}

.show-chat >>> .header {
  padding: var(--column);
  height: 13%;
  max-height: 70px;

}

.main-content-agent .title,
p,
.chronometer {
  color: var(--blue-grey);
}

.header {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.header > div {
  display: inline-flex;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.console-chat {
  padding: 0!important;
  flex-grow: 1;
  height: 87%;
}

.script {
  font-size: 14px;
}

.script >>> li {
  list-style: none;
}

.title {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.go-back-button {
  display: none;
}
</style>
